import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import React, { useState } from "react";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";


const RequestForm = () => {

  const { getAccessTokenSilently } = useAuth0();
  const [attendees, setAttendees] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const payload = {
      'summary': event.target['event-name'].value,
      'attendees': attendees.map(email => email.trim()),
      'description': event.target.description.value
    };

    const url = "https://cpdough--calendar-prep-api-one-time-cal-prep.modal.run";
    const accessToken = await getAccessTokenSilently();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    };

    try {
      const response = await axios.post(url, payload, { headers });
      console.log(response);
      alert(`Success: ${response.data.message}`);
    } catch (error) {
      console.error(error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAttendeeChange = (index, event) => {
    const values = [...attendees];
    values[index] = event.target.value;
    setAttendees(values);
  };

  const handleAddAttendee = () => {
    setAttendees([...attendees, '']);
  };

  const handleRemoveAttendee = (index) => {
    const values = [...attendees];
    values.splice(index, 1);
    setAttendees(values);
  };

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
    <div
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      aria-hidden="true"
    >
      <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#000080] to-[#add8e6] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div
      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      aria-hidden="true"
    >
      <div
        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#000080] to-[#add8e6] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>


    <div className="mx-auto pb-4 max-w-2xl lg:text-center">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Prep for my Next Meeting
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Fill in the details of your next meeting. Calendar Prep AI will conduct research for your meeting and send you findings via e-mail.
        </p>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="max-w-2xl mx-auto space-y-12">
        <div className="pb-1">

          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="event-name" className="block text-sm font-medium leading-6 text-gray-900">
                Meeting Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="event-name"
                  id="event-name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="attendees" className="block text-sm font-medium leading-6 text-gray-900">
                Attendee Emails
              </label>
              {attendees.map((attendee, index) => (
              <div className="sm:col-span-6 flex items-center" key={index}>
              <input
                type="email"
                id={`attendee-${index}`}
                value={attendee}
                onChange={event => handleAttendeeChange(index, event)}
                placeholder="tim@apple.com"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                type="button"
                onClick={() => handleRemoveAttendee(index)}
                className="ml-2 rounded-md bg-red-600 p-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            ))}
            <div className="sm:col-span-6">
              <button
                type="button"
                onClick={handleAddAttendee}
                className="mt-2 rounded-md bg-indigo-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 inline-flex items-center"
              >
                <PlusIcon className="h-5 w-5 mr-2" /> Add Attendee
              </button>
            </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description of Meeting
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter a description of the meeting including the goal, plus any other context you think would be helpful for AI."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-6 pb-12 flex items-center justify-center gap-x-6">
        <button
          type="submit"
          disabled={isLoading}
          className="rounded-md bg-indigo-600 px-6 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Generate Calendar Prep E-mail
        </button>
      </div>
    </form>
    </div>
  )
}

export default RequestForm;