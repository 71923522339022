import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [buttonText, setButtonText] = useState("Generate Example E-mail");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const runCalPrepExample = async () => {
    setButtonText("Generating Example E-mail");
    setButtonDisabled(true);
    const accessToken = await getAccessTokenSilently();

    const url = "https://cpdough--calendar-prep-api-one-time-cal-prep.modal.run";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: headers
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      alert(`Success: ${data.message}`); // Directly use the data in the alert
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      alert(`Error: ${response.status} - ${response.statusText}`);
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div>
        <div className="space-y-12">
        <div className="pb-2">
          <h1 className="text-3xl text-center font-bold tracking-tight leading-7 text-gray-900">My Account</h1>
          <br />
          
          <h2 className="text-base text-center font-semibold leading-7 text-gray-900">Welcome to Calendar Prep, {user.name}!</h2>
          <h2 className="text-base text-center font-semibold leading-7 text-gray-900">Getting Started</h2>
                  <ol className="list-decimal text-center list-inside mt-1 text-sm leading-6 text-gray-600">
                    <li>Subscribe via Stripe (below)</li>
                    <li>Use our <a href="/eventprep" className="underline">Meeting Prep</a> App</li>
                    <li>Optionally, connect using your work calendar to get automated Calendar Prep e-mails</li>
                  </ol>
                <br />
        </div>

        <div className="flex flex-column justify-between justify-center border-b border-gray-900/10 pb-12">
          
          <div className="w-full md:w-1/4 py-4 mx-auto flex flex-col items-center justify-center">
            <h1 className="text-3xl text-center font-bold tracking-tight leading-7 text-gray-900">Subscribe Now</h1>
            <stripe-buy-button
              buy-button-id="buy_btn_1NzLm2KFxdT22Qzoqx8VIojO"
              publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw"
            >
            </stripe-buy-button>
          </div>
        </div>


          <div className="w-full py-4">
            <h1 className="text-3xl text-center font-bold tracking-tight leading-7 text-gray-900">Manage My Subscription</h1>
            <p className="mt-1 text-md text-center mt-4 leading-6 text-gray-900">
             If you already have a subscription and want to modify it, <a href="https://billing.stripe.com/p/login/7sI8zw5NL86i0aA5kk" className="text-blue-500 hover:underline">click here.</a>
          </p>
          </div>
        </div>

        </div>

    )
  );
};

export default Profile;