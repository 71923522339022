import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="flex justify-center space-x-4 p-4 z-10">
      <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 z-10">Home</Link>
      <Link to="/privacy-policy" className="text-sm font-semibold leading-6 text-gray-900 z-10">Privacy Policy</Link>
    </footer>
  );
};

export default Footer;