import LogoutButton from './LogoutButton';
import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import { HashLink as Link } from 'react-router-hash-link';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({ isAuthenticated, loginWithRedirect }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="sticky z-20">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            {!mobileMenuOpen && (
              <>
              <span className="sr-only">CalendarPrep</span>
              <img className="h-14 w-auto" src="/hr_logo5.png" alt="Logo" />
              </>
            )}
          </a>
        </div>
        <div className="flex lg:hidden">
          {!mobileMenuOpen && (
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          )}
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link smooth to="/#how-it-works" className="text-sm font-semibold leading-6 text-gray-900">
            How it Works
          </Link>
          <Link smooth to="/#pricing" className="text-sm font-semibold leading-6 text-gray-900">
            Pricing
          </Link>
          <a href="/eventprep" className="text-sm font-semibold leading-6 text-gray-900">
            Prep for a Meeting
          </a>
          <a href="/profile" className="text-sm font-semibold leading-6 text-gray-900">
            My Account
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        {isAuthenticated ? (
            <LogoutButton />
            ) : (
            <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={loginWithRedirect}
            >
                Login
            </button>
            )}

        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="/hr_logo5.png"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link smooth to="/#how-it-works" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  How it Works
                </Link>
                <Link smooth to="/#pricing" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Pricing
                </Link>
                <Link smooth to="/#aboutus" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  About Us
                </Link>
                <a href="/eventprep" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Prep for a Meeting
                </a>
                <a href="/profile" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  My Account
                </a>
              </div>
              <div className="py-6">
              {isAuthenticated ? (
                <LogoutButton />
                ) : (
                <button
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={loginWithRedirect}
                >
                    Login
                </button>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
