import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import HomePage from './HomePage';
import Profile from './Profile';
import Header from "./Header";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import EventPrep from "./EventPrep";
import './App.css';
import ReactGA from "react-ga4";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const HeaderWrapper = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return <Header isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />;
};

export default function App() {
  useEffect(() => {
    ReactGA.initialize("G-875V6X6R6V");
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
      domain="calendarprep.us.auth0.com"
      clientId="KNtJuUOM6H9FsR4N6qbA0CBew27pa447"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://calendarprep.us.auth0.com/api/v2/",
          scope: "openid profile email"
        }}
      >
      <HeaderWrapper />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
            path="/profile"
            element={<ProtectedRoute component={Profile} />}
        />
        <Route
            path="/eventprep"
            element={<ProtectedRoute component={EventPrep} />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}